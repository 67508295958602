<template>
  <t-modal
    :name="`rental-accept`"
    :variant="`confirmation`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="!isLoading"
    :clickToClose="!isLoading"
    @closed="onClose"
  >
    <section class="overflow-auto h-96">
      <section class="px-4 pt-4 text-2xl font-extrabold text-center">
        Accept Rent Request
        <p class="text-sm text-gray-400 font-bold">
          {{ currentVehicleModel }}
        </p>
      </section>

      <div class="px-5 pt-5">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form
            @submit.prevent="handleSubmit(onConfirm())"
            enctype="multipart/form-data"
          >
            <AppInput
              v-if="!should_start_rent_after_accepting"
              v-model="form.deliveryman"
              type="richselect"
              name="deliveryman"
              label="Deliveryman"
              rules="required"
              placeholder="Select a deliveryman"
              text-attribute="text"
              value-attribute="value"
              :fetch-options="onFetchDeliverymen"
              @click="OnOthers"
            />
            <!-- <AppInput
              v-model="form.vehicle.qr_code"
              type="text"
              name="vehicle qr"
              label="Vehicle QRCode"
              rules="required"
              placeholder="e.g. 10001001"
              readonly
              @click="onSearch"
            /> -->
            <div class="relative mb-1 ">
              <AppInput
                v-model="globalSearch"
                type="text"
                placeholder="Search vehicles by Name / QRCode"
                @focus="focusUpdate"
                @blur="blurUpdate"
                class="px-2 py-1 search-area"
                :isSearch="true"
              />

              <div
                class="absolute right-0 left-0 top-auto z-10 py-1 bg-white rounded rounded-t-none border border-t-0 "
              >
                <template v-if="isLoadingVehicles && isShow">
                  <p class="mx-1">Loading...</p>
                </template>
                <!-- keep-typing -->
                <!-- <template
                v-if="
                  globalSearch.length <= 1 && globalSearchResults.length === 0
                "
              >
                <div
                  class="flex justify-between items-center py-2 px-2 text-sm font-medium"
                >
                  <div>Keep typing...</div>

                  <i class="block text-green-500 fas fa-cog fa-spin" />
                </div>
              </template> -->
                <!-- /keep-typing -->

                <!-- not-found -->
                <template
                  v-if="
                    !isLoadingVehicles &&
                      globalSearchResults.length === 0 &&
                      isShow
                  "
                >
                  <div
                    class="flex justify-between items-center py-2 px-2 text-sm font-medium"
                  >
                    <div>No vehicle found for this model</div>

                    <i class="block text-red-500 fas fa-exclamation-circle" />
                  </div>
                </template>
                <!-- /not-found -->

                <!-- results -->
                <div
                  v-if="
                    !isLoadingVehicles &&
                      globalSearchResults.length > 0 &&
                      isShow
                  "
                  class="list-container"
                >
                  <div
                    class="flex justify-between items-center py-2 px-2 text-sm cursor-pointer hover:bg-gray-100 "
                    v-for="result in globalSearchResults"
                    :key="result.id"
                    @click="onSet(result)"
                  >
                    <div class="w-6/12">
                      <span class="text-gray-700">{{ result.name }}</span>
                      <br />
                      <span class="text-xs text-gray-500">{{
                        result.qr_code
                      }}</span>
                    </div>

                    <div class="w-4/12">
                      <span class="text-gray-400 text-sm">{{
                        result.organization_vehicle_model.title || '--'
                      }}</span>
                    </div>

                    <div class="w-2/12 flex items-center justify-end">
                      <HeartbeatStatus
                        :status="result.lock.is_operational"
                        :text-class="`ml-2 text-xs`"
                        :is-available="result.is_available"
                      />
                    </div>
                  </div>
                </div>
                <!-- /results -->
              </div>
            </div>
            <AppInput
              v-if="!should_start_rent_after_accepting"
              type="number"
              name="eta"
              label="Delivery ETA (min)"
              rules="required"
              placeholder="Enter an estimated time(between 1 to 60 mins) of delivery"
              v-model="form.eta"
              @click="OnOthers"
            />
            <button type="submit" ref="submitButtonRef" class="hidden">
              Confirm
            </button>
          </form>
        </ValidationObserver>
      </div>

      <div class="absolute bottom-0 w-full">
        <section
          class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50 "
        >
          <AppButton
            :disabled="isLoading"
            :variant="`secondary`"
            :class="`w-1/2`"
            @click="onClose"
          >
            Cancel
          </AppButton>

          <AppButton
            :variant="`primary`"
            :class="`w-1/2`"
            :isLoading="isLoading"
            :disabled="isLoading"
            @click="$refs.submitButtonRef.click()"
          >
            Accept
          </AppButton>
        </section>
      </div>
    </section>
  </t-modal>
</template>

<script>
import { xMan } from '@/utils'
import { useEndpoints } from '@/composables'
import Qs from 'qs'
import HeartbeatStatus from '@/components/badge/HeartbeatStatus.vue'
export default {
  props: {
    rentId: {
      type: String,
      required: true,
    },
    currentVehicleCategory: {
      type: String,
      required: true,
    },
    currentVehicleModel: {
      type: String,
      required: false,
      default: '',
    },
    should_start_rent_after_accepting: {
      type: Boolean,
      required: true,
    },
  },
  components: { HeartbeatStatus },
  mounted() {
    this.$modal.show('rental-accept')
  },
  data() {
    return {
      isLoading: false,
      isLoadingVehicles: false,
      globalSearch: '',
      globalSearchResults: [],
      isShow: false,
      form: {
        deliveryman: null,
        vehicle: {
          qr_code: null,
          id: null,
        },
        eta: null,
      },
      deliveryETAOptions: [
        {
          text: '30 min',
          value: 30,
        },
        {
          text: '1 hour',
          value: 60,
        },
        {
          text: '1 hour 30 min',
          value: 90,
        },
        {
          text: '2 hours',
          value: 120,
        },
        {
          text: '2 hours 30 min',
          value: 150,
        },
        {
          text: '3 hours',
          value: 180,
        },
      ],
    }
  },
  watch: {
    // updated is string
    globalSearch(updated) {
      if (updated == '') {
        this.globalSearch = ' '
      } else if (updated.length > 0) {
        this.onFetchVehicles(updated)
      } else {
        this.globalSearchResults = []
      }
    },
  },
  methods: {
    async onFetchDeliverymen(query) {
      await this.$http
        .get('/dashboard/rentals/deliverymen/', {
          params: {
            search: query,
          },
        })
        .then(({ data }) => {
          this.deliverymen = data?.data.map((item) => ({
            text: item.full_name,
            value: item.id,
          }))
          // console.log('onFetchUsers', this.users)
        })
        .catch((err) => {
          console.log('onFetchDeliverymen', err, err.response)
        })

      return { results: this.deliverymen }
    },
    // async onFetchVehicles(query) {
    //   await this.$http
    //     .get('/dashboard/vehicles/', {
    //       params: {
    //         search: query,
    //         task_status: 'not_in_task',
    //         sort: 'lock__isOperational',
    //         available: true,
    //         has_lock: '',
    //         status_flag: [{ on_ride: false }],
    //         bike_category: [this.currentVehicleCategory],
    //         service_status: ['YES', 'NO'],
    //         unrented: '',
    //       },
    //       paramsSerializer: function(params) {
    //         return Qs.stringify(params, { arrayFormat: 'repeat' })
    //       },
    //     })
    //     .then(({ data }) => {
    //       this.globalSearchResults = data?.data
    //     })
    //     .catch((err) => {
    //       console.log('onFetchUsersErr', err, err.response)
    //     })

    //   return { results: this.vehicles }
    // },
    async onFetchVehicles(query) {
      this.isLoadingVehicles = true
      console.log('que', query)
      await this.$http
        .get(useEndpoints.vehicleRental.getRentableVehicles(this.rentId), {
          params: {
            search: query,
          },
          paramsSerializer: function(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' })
          },
        })
        .then(({ data }) => {
          this.globalSearchResults = data?.data
        })
        .catch((err) => {
          console.log('onFetchUsersErr', err, err.response)
        })
        .finally(() => (this.isLoadingVehicles = false))

      return { results: this.vehicles }
    },
    onSearch() {
      this.isShow = !this.isShow
      if (this.isShow) {
        this.globalSearch = this.form.vehicle.qr_code ?? ' '
      }
    },
    OnOthers() {
      this.isShow = false
    },
    onSet(vehicle) {
      this.isShow = false
      this.globalSearch = vehicle.qr_code
      this.form.vehicle.qr_code = vehicle.qr_code
      this.form.vehicle.id = vehicle.id
    },
    onClose(shouldUpdate) {
      this.$emit('close', shouldUpdate)
    },
    focusUpdate(e) {
      console.log('update-focus', e.target.value)
      this.isShow = true
      this.onFetchVehicles(e.target.value || '')
    },
    // blurUpdate(e) {
    //   this.isShow = false
    //   console.log('update-blur', e.target.value)
    // },
    onConfirm() {
      const self = this
      const form = this.form
      this.isShow = false
      this.isLoading = true

      let formDataProxy

      if (this.should_start_rent_after_accepting && form.vehicle) {
        formDataProxy = {
          status: 'ACCEPTED',
          vehicle: form.vehicle.id,
        }
      } else {
        if (
          form.deliveryman &&
          form.vehicle &&
          form.eta &&
          form.eta >= 1 &&
          form.eta <= 60
        ) {
          formDataProxy = {
            status: 'ACCEPTED',
            vehicle: form.vehicle.id,
            deliveryman: form.deliveryman,
            estimated_delivery_time: form.eta,
          }
        }
      }

      // if (form.eta) {
      //   formDataProxy.estimated_delivery_time = form.eta
      // }

      const formData = new xMan(formDataProxy).toFormData()

      this.$http
        .patch(useEndpoints.vehicleRental.update(this.rentId), formData)
        .then((res) => {
          console.log(res)
          this.$emit('refresh')
          self.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: `Rental Request Accepted`,
              text: `The rental request has been accepted successfully`,
            },
            5000
          )
          self.$store.dispatch('fsTable/fetchData')
        })
        .catch((err) => {
          console.log('onConfirm', err, err.response)
          self.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: `Rental Request Update Error`,
              text: `Rental request could not be accepted`,
            },
            5000
          )
        })
        .finally(() => {
          self.isLoading = false
          self.onClose(true)
        })
    },
  },
}
</script>
<style scoped lang="scss">
.search-area {
  width: 100%;
  height: 52px;
  border-radius: 7px;
  border: solid 1px #ffffff;
  background-color: #f2f2f2;
}
.list-container {
  max-height: 10rem;
  overflow-y: auto;
}
</style>
